.App {
  height: 100%;
  max-height: 100dvh;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.App-header {
  /* height: 20px; */
  padding: 15px;
  display: flex;
  justify-content: space-between;
}

.App-header h1 {
  padding: 0;
  margin: 0;
  font-size: 1.5em;
  font-weight: normal;
  user-select: none;
}

.App-header div {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.App-message {
  font-size: 0.6em;
}

.App-message.issue {
  color: red;
}

.App-message.success {
  color: green;
}

.App-message.info {
  color: blue;
}

button,
input[type=text],
select {
  margin: 0 5px;
  padding: 5px;
  border: none;
  border-radius: 5px;
  background-color: #f0f0f0;
  cursor: pointer;
  outline: 1px solid lightgrey;
  transition: outline 250ms;
}

button:hover {
  outline-color: darkgrey;
}

button:active {
  color: white;
  background-color: black;
}

.App-pythonCode {
  margin: 20px;
  border: 1px solid lightgrey;
  padding: 5px;
  border-radius: 5px;
  background-color: #f0f0f0;
}

.App-body {
  flex-shrink: 1;
  height: 100dvh;
}

.App-body .injectionDiv {
  height: unset;
  width: unset;
  position: absolute;
  margin: 0;
  padding: 0;
}

@media (prefers-reduced-motion: no-preference) {}

.App-saveCode {
  margin-left: 7.5px;
}

.App-colorCode-codeLetter {
  cursor: default;
}

.App-colorCode-codeLetter,
.App-colorCode-codeLetter.invert::selection {
  color: var(--App-colorCode-codeLetter-color, black);
  background-color: white;
}

.App-colorCode-codeLetter::selection,
.App-colorCode-codeLetter.invert {
  background-color: var(--App-colorCode-codeLetter-color, black);
  color: white;
}